
<template>
  <v-container
    class="text-center"
    fill-height
    style="height: calc(100vh - 58px)"
  >
    <v-row align="center">
      <v-col>
        <div v-if="loading">
          <v-progress-circular
            color="primary darken-2"
            indeterminate
            size="64"
          ></v-progress-circular>

          <p>Verifying token...</p>
        </div>

        <div v-else-if="!isVerified">
          <h1 class="display-2 primary--text">
            Whoops, unable to verify token
          </h1>

          <p>Please ensure you clicked the lastest link sent to your email</p>
        </div>
        <div v-else>
          <h1 class="display-2 primary--text">
            Whoops, unable to verify token
          </h1>

          <p>Please ensure you clicked the lastest link sent to your email</p>
        </div>

        <v-btn v-if="!loading" :to="{ name: 'login' }" color="primary" outlined>
          Back to Login
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
</style>

<script>
import { AuthService } from '@/services/api/auth.service';
import CoreMixin from '@/mixins/core.mixin';

const authService = new AuthService();

export default {
  mixins: [CoreMixin],

  data() {
    return {
      loading: true,
      isVerified: false
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      try {
        await this.verifyToken();
      } catch (e) {
        this.isUnexpectedError = true;
      }
    },

    async verifyToken() {
      this.loading = true;

      try {
        const token = this.$route.params.token;
        const { data } = await authService.verifyForgottenPassword(token);
        this.isVerified = data;
      } catch (e) {
        if (e.response && e.response.status === 404) return;

        this.isVerified = false;
        this.httpErrorHandler(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
